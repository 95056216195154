import { submitMerge } from "./request";

function sendViewTime(time: string) {
  submitMerge({ ot_MinViewTime: [time] });
}

export function init() {
  setTimeout(function () {
    return sendViewTime("2sec");
  }, 2000);
  setTimeout(function () {
    return sendViewTime("5sec");
  }, 5000);
}
