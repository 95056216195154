import { Action, Feature } from "./feature-typing";
import { moduleLogger } from "./util";

const log = moduleLogger.scope("feature");

/**
 *
 *
 *
 *
 */
export function parseAction(inputAction: Partial<Action>): Action {
  if (!inputAction) {
    throw new Error("Missing mandatory action");
  }

  if (!inputAction.name) {
    throw new Error('Missing "name" in action');
  }

  if (!inputAction.features) {
    throw new Error('Missing "features" in action');
  }

  return {
    name: inputAction.name,
    features: inputAction.features.map((f) => parseFeature(f)),
  };
}

/**
 *
 *
 *
 *
 */
 
export function parseFeature(inputFeature: Partial<Feature>): Feature {
  /*               */
  if (!inputFeature.id) {
    throw new Error('Missing "id" in feature');
  }

  const outputFeature: Feature = {
    id: inputFeature.id,
  };

  /*              */
  if ("labels" in inputFeature) {
    outputFeature.labels = inputFeature.labels;
  }

  const nonemptyFields: Array<keyof Feature> = [
    "status",
    "name",
    "parentId",
    "variationId",
    "position",
  ];

  const inputKeys = Object.keys(inputFeature);

  nonemptyFields
    .filter((known) => inputKeys.includes(known))
    .forEach(<K extends keyof Feature>(fieldName: K) => {
      const value = inputFeature[fieldName];
      if (value === undefined || value === "" || value === null) {
        log.error(
          "Ignored field for feature id",
          outputFeature.id,
          `Optional field '${fieldName}' may not be empty if present.`,
        );
        return;
      }
      outputFeature[fieldName] = value;
    });

  return outputFeature;
}
