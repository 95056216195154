import { Feature, MiniAction } from "./feature-typing";
import { doPostRequest } from "./request";
import {
  GateDataContainer,
  MergePayload,
  MiniActionPayload,
  OnLateMergeStrategy,
} from "./request-typings";
import { serializedObjectSize } from "./util";



const isBeaconApiSupported =
  "navigator" in window && typeof window.navigator.sendBeacon === "function";

/**
 *
 *
 */
export function canBatchRequests(): boolean {
  return isBeaconApiSupported;
}

export interface SelfTrigger {
  enabled: boolean;
  delay: number;
  initialized: boolean;
}

export interface BatchMerge {
  mergeValues: GateDataContainer;
  onLate: OnLateMergeStrategy;
  features?: Feature[];
}

/**
 *
 *
 *
 *
 *
 */
export class Batcher<T> {
  sendBatch: (url: string, batch: Batch<T>) => void;
  maxBatchBytes: number;
  batches: Map<string, Batch<T>>;
  selfTrigger: SelfTrigger;
  onlyOnce: boolean;
  /**
 *
 *
 *
 *
 *
 *
 */
   
  constructor(
    sendBatch: (url: string, message: Batch<T>) => void,
    onlyOnce: boolean = false,
    selfTriggerDelay: number = -1,
    maxBatchBytes: number = 55000,
  ) {

    this.sendBatch = sendBatch;
    this.maxBatchBytes = maxBatchBytes;
    this.batches = new Map();
    this.onlyOnce = onlyOnce
    this.selfTrigger = {
      enabled: selfTriggerDelay > 0,
      delay: selfTriggerDelay,
      initialized: false,
    };
  }
  private onceBatchSent = false;

  /**
 *
 *
 *
 */
  storeEntry(message: Message<T>) {
    const messageSize = serializedObjectSize(message);

    /*                                                                      */
    const batch = this.batches.get(message.key) || {
      browserId: message.browserId,
      url: message.url,
      clientMergeId: message.clientMergeId,
      entries: [],
      size: 0,
    };

    /*                                                 */
    const updatedBatch = {
      ...batch,
      entries: [...batch.entries, ...message.entries],
      size: batch.size + messageSize,
    };
    this.batches.set(message.key, updatedBatch);

    /*                                                                    */
    if (updatedBatch.size > this.maxBatchBytes) {
      this.sendBatch(message.key, updatedBatch);
      this.batches.delete(message.key);
    }

    /*                                                         */
    if (this.selfTrigger.enabled && !this.selfTrigger.initialized) {
      setTimeout(() => {
        this.sendBatches();
      }, this.selfTrigger.delay);
      this.selfTrigger.initialized = true;
    }
  }

  /**
 *
 *
 *
 */
  sendBatches() {
    this.batches.forEach((batch, url) => this.sendBatch(url, batch));
    this.batches = new Map();
    this.selfTrigger.initialized = false;
    this.onceBatchSent = true;
  }

  /**
 *
 *
 */
  shouldBatch() {
    return canBatchRequests() && !(this.onlyOnce && this.onceBatchSent); 
  }
}

/**
 *
 *
 *
 *
 *
 */
export function batchMergeRequest(gateUrl: string, browserId: string, payload: MergePayload): void {
  const entry: BatchMerge = {
    mergeValues: payload.mergeValues,
    onLate: payload.onLate,
    features: payload.features,
  };

  mergeBatcher.storeEntry({
    key: gateUrl,
    browserId,
    url: payload.url,
    clientMergeId: payload.clientMergeId,
    entries: [entry],
  });
}

/**
 *
 *
 *
 *
 *
 */
export function batchMiniActionRequest(
  gateUrl: string,
  browserId: string,
  payload: MiniActionPayload,
): void {
  miniactionBatcher.storeEntry({
    key: gateUrl,
    browserId,
    url: payload.url,
    entries: payload.updates,
  });
}

/**
 *
 *
 *
 *
 */
export function sendMergeBatch(url: string, batch: Batch<BatchMerge>) {
  const payload = {
    browserId: batch.browserId,
    url: batch.url,
    clientMergeId: batch.clientMergeId,
    merges: batch.entries,
  };

  doPostRequest(url, payload);
}

/**
 *
 *
 *
 *
 */
export function sendMiniActionBatch(url: string, batch: Batch<MiniAction>) {
  const payload: MiniActionPayload = {
    browserId: batch.browserId,
    url: batch.url,
    updates: batch.entries,
  };

  doPostRequest(url, payload);
}

export const mergeBatcher = new Batcher(sendMergeBatch, true)
export const miniactionBatcher = new Batcher(sendMiniActionBatch, false, 1000)

/*                                               */
setTimeout(() => {
  mergeBatcher.sendBatches();;
}, 1500);

/*                                   */
window.addEventListener("beforeunload", () => {
  miniactionBatcher.sendBatches();
  mergeBatcher.sendBatches();;
});

interface Message<EntryType> {
  /**
 *
 */
  key: string;
  /**
 *
 */
  browserId: string;
  /**
 *
 */
  url: string;
  /**
 *
 */
  clientMergeId?: string;
  /**
 *
 */
  entries: Array<EntryType>;
}

interface Batch<EntryType> {
  /**
 *
 */
  browserId: string;
  /**
 *
 */
  url: string;
  /**
 *
 */
  entries: Array<EntryType>;
  /**
 *
 */
  clientMergeId?: string;
  /**
 *
 */
  size: number;
}
