import { DeviceLabels, DeviceProperties, NavigationLabels } from "./browser-basics-typings";
import { provideContext } from "./context-provider";
import { getCurrentHost } from "./host";
import { orientation as deviceOrientation } from "@otto-ec/global-resources/device";
import { breakpoint } from "@otto-ec/global-resources/breakpoint";

function safeGet<T>(fun: () => T): T | "unbekannt" {
  try {
    return fun();
  } catch (e) {
    return "unbekannt";
  }
}

/**
 *
 *
 */
export function getDocumentReferrer(): string | null {
  const context = provideContext();
  if (context.isLayerContext || context.isReplacedContext) {
    return getCurrentHost() + context.artificialReferrerPath;
  } else if (document.referrer) {
    return document.referrer;
  }

  return null;
}

/**
 *
 *
 */
export function getDeviceProperties(): DeviceProperties {
  return {
    breakpoint: safeGet(() => breakpoint.getCurrentBreakpoint().toUpperCase()),
    orientation: safeGet(() => deviceOrientation.getCurrentOrientation()),
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    mediaQueries: "matchMedia" in window || "msMatchMedia" in window,
  };
}

/**
 *
 *
 *
 */
export function getNavigationLabels(messageType?: string): NavigationLabels {
  const context = provideContext();
  const labels: NavigationLabels = {};

  if (context.isLayerContext) {
    labels.ts_ContextLevel = [context.contextLevel.toString()];
  }

  const docRef = getDocumentReferrer();
  if (
    messageType !== "event" &&
    messageType !== "eventMerge" &&
    messageType !== "action" &&
    docRef &&
    context.needsReferrerSend()
  ) {
    labels.ts_DocReferrer = [docRef];
  }

  return labels;
}

/**
 *
 *
 *
 *
 */
export function getDeviceLabels(orientation?: string, breakPoint?: string): DeviceLabels {
  const deviceProperties = getDeviceProperties();
  return {
    ts_Innersize: [`${deviceProperties.innerWidth}x${deviceProperties.innerHeight}`],
    ts_Screen: [`${deviceProperties.screenWidth}x${deviceProperties.screenHeight}`],
    ot_Orientation: [orientation || deviceProperties.orientation],
    ot_Breakpoint: [breakPoint || deviceProperties.breakpoint],
  };
}
