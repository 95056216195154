import { readFromDataAttribute, moduleLogger } from "./util";
import { get as getCookie } from "@otto-ec/global-resources/cookie";

const log = moduleLogger.scope("compute-ids");

const PAGE_MERGE_ID_ERROR_MESSAGE =
  "getting sfid could not find an SFID. Check the HTML and make sure you render a div with class=ts-bct and" +
  " a data-ts_sfid attribute containing the SFID";

/**
 *
 *
 *
 *
 */
export function computePageMergeId(parentId?: string): string | null {
  const query = parentId ? `#${parentId} .ts-bct` : ".ts-bct";
  const idNode = document.querySelector(query);

  if (!idNode) {
    return null;
  }

  const sfid = readFromDataAttribute(idNode, "ts_sfid");

  if (!sfid) {
    log.error(PAGE_MERGE_ID_ERROR_MESSAGE);
    return null;
  }

  return encodeURIComponent(sfid);
}

export function computeScid() {
  return new Date().getTime().toString();
}

export function getBrowserId() {
  return getCookie("BrowserId");
}

export function createRandomId(idLength: number) {
  const chars = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  let id = "";
  for (let i = 0; i < idLength; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    id += chars[randomIndex];
  }
  return id;
}
