import { isPlainObject } from "@otto-ec/global-resources/core";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export type DataContainer = Record<string, Array<string>>;

/*          */
export function parseDataContainer(input: Record<string, unknown>): DataContainer {
  function shouldStringify(input: unknown): boolean {
    return (
      input !== null &&
      input !== undefined &&
      (typeof input === "object" ||
        (typeof input === "number" && !isNaN(input)) ||
        typeof input === "boolean")
    );
  }
  if (!isPlainObject(input)) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(input)
      .map(([key, value]) => {
        if (typeof value === "string") {
          return [key, [value]];
        }
        if (Array.isArray(value)) {
          return [
            key,
            value
              .map((value) => {
                if (typeof value === "string") {
                  return value;
                }
                if (shouldStringify(value)) {
                  return JSON.stringify(value);
                }
              })
              .filter((value): value is string => value !== undefined),
          ];
        }
        if (shouldStringify(value)) {
          return [key, [JSON.stringify(value)]];
        }
      })
      .filter(Boolean) as never,
  );
}
