import { createRandomId } from "./compute-ids";
import { isEmptyObject } from "@otto-ec/global-resources/core";
import { provideContext } from "./context-provider";

export function toQueryString(params: Record<string, string>) {
  if (!params || isEmptyObject(params)) {
    return "";
  }

  const pairs = Object.keys(params).map((key) => {
    const encodedValue = encodeURIComponent(params[key]);
    return [key, encodedValue].join("=");
  });
  return `?${pairs.join("&")}`;
}

/**
 *
 *
 *
 */
export function getMergeParameters(): string {
  const context = provideContext();
  const params = Object.assign(
    {
      scid: context.currentScid,
      cachefix: createRandomId(10),
      ts_Type: "merge",
    },
    context.currentPageMergeId ? { psfids: context.currentPageMergeId } : null,
  );
  return toQueryString(params).slice(1);
}

/**
 *
 *
 *
 *
 */
export function getPageId(): string | null {
  const context = provideContext();
  return context.currentPageMergeId;
}
