import { Context } from "./context";
import { computeScid, computePageMergeId } from "./compute-ids";

/**
 *
 *
 *
 */
function provider(): () => Context {
  let context: Context | undefined;

  return () => {
    if (!context) {
      context = new Context(computeScid, computePageMergeId);
    }

    return context;
  };
}

export const provideContext = provider();
