import { storage as createStorage } from "@otto-ec/global-resources/storage";

const storage = createStorage(window.sessionStorage);

export function setInternal(value: string) {
  storage.setItem("ts-accesspath-internal", value);
}

export function getInternal(): string | null {
  return storage.getItem("ts-accesspath-internal");
}

export function setUnloadedLocationHref(value: string) {
  storage.setItem("ts-unloaded-location-href", value);
}

function getUnloadedLocationHref() {
  return storage.getItem("ts-unloaded-location-href");
}

function getWindowLocationHRef() {
  return window.location.href;
}

function newInternalState(referrer: string | null, internal: string | null) {
  const uri = document.createElement("a");

  if (referrer) {
    uri.href = referrer;
    const referrerHost = uri.host;
    if (referrerHost.match(/(?:^|\.)otto\.de$/)) {
      return "true";
    }
  }
  return internal || "false";
}

export function update(referrer: string | null) {
  setInternal(newInternalState(referrer, getInternal()));
}

export function storeUnloadedLocationHref() {
  setUnloadedLocationHref(getWindowLocationHRef());
}

export function isInternal(): boolean {
  return getInternal() === "true";
}

export function isReloadedPage(): boolean {
  return getUnloadedLocationHref() === getWindowLocationHRef();
}

export interface MergeParameters {
  ts_AccPathInternal: [string];
  ts_ReloadedPage: [string];
}

export function getMergeParameters(): MergeParameters {
  return {
    ts_AccPathInternal: [isInternal().toString()],
    ts_ReloadedPage: [isReloadedPage().toString()],
  };
}
