import { isPlainObject } from "@otto-ec/global-resources/core";
import { logger } from "@otto-ec/global-resources/debug";
import { DataContainer } from "./datacontainer";
import { GateDataContainer } from "./request-typings";

export const moduleLogger = logger("tracking.bct");

export const trackingInternalsKey = Symbol();
window[trackingInternalsKey] = {
  TRACKING_hashtrackinginitialized: false,
  TRACKING_linkdatainitialized: false,
  TRACKING_moveInitialized: false,
  TRACKING_bctdatainitialized: false,
  TRACKING_echinitialized: false,
};
export const internals = window[trackingInternalsKey];

export function readFromDataAttribute(element: Element, name: string): string | null {
  /*                                                                                */
  if (element !== null) {
    return element.getAttribute(`data-${name}`);
  }

  return null;
}

export function getLinkData(
  target: Element,
  attributeName: string,
): Record<string, unknown> | null {
  const tsLinkData = readFromDataAttribute(target, attributeName);

  if (!tsLinkData) {
    return null;
  }

  try {
    return JSON.parse(tsLinkData);
  } catch (parseError) {
    return null;
  }
}

export function isExternalLink(url: string): boolean {
  return !/^https?:\/\/[a-zA-Z]*\.?otto\.de/.test(url);
}

/**
 *
 */
export function flattenAndTransformDataContainer(dataContainer: DataContainer): GateDataContainer {
  const flatten = (array: Array<Record<string, string>>, key: keyof typeof dataContainer) => {
    const asArray = (value: string | Array<string>) => {
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    };
    const value = dataContainer[key];
    asArray(value).forEach((entry) => {
      const empty: Record<string, string> = {};
      if (isPlainObject(entry) || Array.isArray(entry)) {
        empty[key] = JSON.stringify(entry);
      } else {
        empty[key] = entry;
      }
      array.push(empty);
    });

    return array;
  };
  const buildNewObject = (object: Record<string, string>) => {
    const key = Object.keys(object)[0];
    const value = object[key];
    return { labelName: `${key}`, labelValue: `${value}` };
  };

  return Object.keys(dataContainer).reduce(flatten, []).map(buildNewObject);
}

/**
 *
 *
 *
 */

export function serializedObjectSize(obj: object): number {
  const str = JSON.stringify(obj);

  let bytes = str.length;
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) {
      bytes++;
    } else if (code > 0x7ff && code <= 0xffff) {
      bytes += 2;
    }

    /*              */
    if (code >= 0xdc00 && code <= 0xdfff) {
      i--;
    }
  }

  return bytes;
}

/**
 *
 *
 *
 */
export function cleanUpUrl(url: string): string {
  return url
    .split("/")
    .filter((segment) => segment !== "")
    .map(encodeURIComponent)
    .join("/");
}
