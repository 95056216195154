import { DataContainer } from "./datacontainer";
import { sendMergeToGate } from "./request";
import { moduleLogger, internals } from "./util";
import { get as getFragment, remove as removeFragment } from "@otto-ec/global-resources/fragment";

const log = moduleLogger.scope("hash-tracking");

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function init() {
  if (internals.TRACKING_hashtrackinginitialized) {
    return;
  }
  internals.TRACKING_hashtrackinginitialized = true;

  const domList = document.querySelectorAll(".js_tracking");
  const track = Array.from(domList).some((e) => e.hasAttribute("data-track"));

  if (track) {
    hashtrack();
  }
}

function hashtrack() {
  /*                                   */
  const trackingHash = getFragment("t");
  try {
    const t = !!trackingHash && (JSON.parse(trackingHash) as DataContainer);
    if (!t) {
      return;
    }
    sendMergeToGate(t, "default");
    const urlWithoutTinHash = removeFragment("t", window.location.href) as string;
    const { pathname = "", search = "", hash } = new URL(urlWithoutTinHash);
    window.history.replaceState(
      window.history.state,
      document.title,
      `${pathname}${search}${hash === "#" ? "" : hash}`,
    );
  } catch (e) {
    log.error(`Hash-Tracking failed: '${e}'`);
  }
}
