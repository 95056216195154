import { provideContext } from "./context-provider";

/*                    */
let currentHost: string = `${window.location.protocol}/*                       */

export function resetHost() {
  const context = provideContext();
  context.currentPath = window.location.pathname + window.location.search;
  currentHost = `${window.location.protocol}/*                       */
}

export function getCurrentHost(): string {
  return currentHost;
}

export function setCurrentHost(input: string) {
  currentHost = input;
}
