export class TSID {
  pageMergeId: string | null;
  scid: string;
  currentPath: string;
  sendReferrer: boolean;
  elementId?: string;
  previousPath?: string;

  constructor(
    path: string,
    scid: string,
    pageMergeId: string | null,
    previousPath?: string,
    elementId?: string,
  ) {
    this.scid = scid;
    this.pageMergeId = pageMergeId;
    this.elementId = elementId;
    this.sendReferrer = true;
    this.currentPath = path;
    this.previousPath = previousPath;
  }

  get isReplacement() {
    return this.previousPath !== null && this.previousPath !== undefined;
  }
}

/**
 *
 *
 *
 *
 *
 *
 */
export class Context {
  computeScid: () => string;
  computePageMergeId: (parentId?: string) => string | null;
  stack: TSID[];

  constructor(computeScid: () => string, computePageMergeId: (domId?: string) => string | null) {
    this.computeScid = computeScid;
    this.computePageMergeId = computePageMergeId;

    const tsid = new TSID(
      window.location.pathname + window.location.search,
      this.computeScid(),
      this.computePageMergeId(),
    );
    this.stack = [tsid];
  }

  /**
 *
 */
  get contextLevel(): number {
    return Math.max(0, this.stack.length - 1);
  }

  /**
 *
 */
  get isLayerContext(): boolean {
    return this.contextLevel > 0;
  }

  /**
 *
 *
 */
  get isReplacedContext(): boolean {
    return this.currentTsid.isReplacement;
  }

  /**
 *
 */
  get currentTsid(): TSID {
    return this.stack[this.stack.length - 1];
  }

  /**
 *
 */
  get currentPath(): string {
    return this.currentTsid.currentPath;
  }

  /**
 *
 */
  set currentPath(path: string) {
    this.currentTsid.currentPath = path;
  }

  /**
 *
 */
  get currentScid(): string {
    if (!this.currentTsid.scid) {
      this.currentTsid.scid = this.computeScid();
    }

    return this.currentTsid.scid;
  }

  /**
 *
 */
  resetScid() {
    this.currentTsid.scid = this.computeScid();
  }

  /**
 *
 *
 */
  needsReferrerSend(): boolean {
    const ret = this.currentTsid.sendReferrer;
    this.currentTsid.sendReferrer = false;
    return ret;
  }

  /**
 *
 */
  get currentPageMergeId(): string | null {
    const { pageMergeId, elementId } = this.currentTsid;
    if (!pageMergeId) {
      this.currentTsid.pageMergeId = this.computePageMergeId(elementId);
    }
    return this.currentTsid.pageMergeId;
  }

  /**
 *
 */
  get basePageMergeId(): string | null {
    const { pageMergeId, elementId } = this.stack[0];
    if (!pageMergeId) {
      this.stack[0].pageMergeId = this.computePageMergeId(elementId);
    }
    return this.stack[0].pageMergeId;
  }

  /**
 *
 */
  get artificialReferrerPath(): string | undefined {
    if (this.isReplacedContext) {
      return this.currentTsid.previousPath;
    }

    return this.basePath;
  }

  /**
 *
 */
  get basePath(): string {
    return this.stack[0].currentPath;
  }

  /**
 *
 *
 */
  resetPageMergeId(parentId?: string): string | null {
    this.currentTsid.pageMergeId = this.computePageMergeId(parentId);
    return this.currentPageMergeId;
  }

  /**
 *
 *
 *
 *
 */
  create(id: string, url: string): Array<TSID> {
    const newTsid = new TSID(url, this.computeScid(), this.computePageMergeId(id), undefined, id);
    this.stack.push(newTsid);

    return this.stack;
  }

  /**
 *
 *
 *
 *
 *
 */
  createReplacement(id: string, url: string, previousPath?: string): Array<TSID> {
    const newTsid = new TSID(
      url,
      this.computeScid(),
      this.computePageMergeId(id),
      previousPath,
      id,
    );
    this.stack.push(newTsid);

    return this.stack;
  }

  /**
 *
 *
 *
 */
  close(): TSID | null {
    const ctx = this.currentTsid;
    const oldContext: TSID = new TSID(
      ctx.currentPath,
      ctx.scid,
      ctx.pageMergeId,
      ctx.previousPath,
      ctx.elementId,
    );

    if (this.stack.length > 1) {
      this.stack.pop();
      return oldContext;
    }

    return null;
  }

  /**
 *
 *
 *
 *
 */
  replace(id: string, url: string): Array<TSID> {
    const toBeReplaced = this.stack.pop();
    const previousPath = toBeReplaced ? toBeReplaced.currentPath : undefined;
    return this.createReplacement(id, url, previousPath);
  }

  /**
 *
 *
 *
 *
 *
 */
  replaceWithPageMergeId(pageMergeId: string, url: string): Array<TSID> {
    const toBeReplaced = this.stack.pop();
    const previousPath = toBeReplaced ? toBeReplaced.currentPath : undefined;
    const newTsid = new TSID(url, this.computeScid(), pageMergeId, previousPath);

    this.stack.push(newTsid);

    return this.stack;
  }

  /**
 *
 */
  reset() {
    this.stack = this.stack.slice(0, 1);
  }
}
